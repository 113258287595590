<template>
  <div>
    <v-layout wrap justify-end>
      <v-flex xs12 md12>
        <v-menu offset-y right>
          <template v-slot:activator="{ attrs, on }">
            <!-- <v-layout wrap justify-center v-bind="attrs" v-on="on" class="hidden-lg-and-up" >
              <v-flex xs12  align-self-center>
                <v-icon large>mdi-account-circle-outline</v-icon>
              </v-flex>
          </v-layout> -->
            <v-layout wrap justify-center v-bind="attrs" v-on="on" class="hidden-md-and-down">
              <v-flex xs2 pt-1 align-self-center>
                <!-- <v-icon large title="Account Info" size="20">
                  mdi-account-star
                </v-icon> -->
                <!-- <v-img src="../../src/assets/icons/settings.png"></v-img> -->
                <v-icon size="170%" color="black">mdi-account-circle-outline</v-icon>
              </v-flex>
              <v-flex xs10 text-left>
                <v-layout wrap>
                  <v-flex style="cursor: pointer" xs11 pt-1 align-self-center>
                    <span v-if="profile.traderId" style="
      font-family: dmsansregular;
      color: black;
      font-size: 14px;
      text-transform: none;
      font-weight:600;

    ">
                      {{ profile.traderId.name }}
                    </span>

                    <span v-if="profile.farmerId" style="
      font-family: dmsansregular;
      color: black;
      font-size: 14px;
      text-transform: none;
      font-weight:600;

    ">
                      {{ profile.farmerId.name }}
                    </span>

                    <span v-if="profile.customerId" style="
      font-family: dmsansregular;
      color: black;
      font-size: 14px;
      text-transform: none;
      font-weight:600;

    ">
                      {{ profile.customerId.name }}
                    </span>
                    <v-icon color="black" style="
                                   
                                    cursor: pointer;
                                  ">mdi-chevron-down</v-icon>

                  </v-flex>
                  <!-- <v-flex xs1 pt-1 align-self-center>
<v-icon color="black">mdi-chevron-down</v-icon>
                  </v-flex> -->
                </v-layout>
              </v-flex>
            </v-layout>
            <v-layout wrap justify-center v-bind="attrs" v-on="on" class="hidden-lg-and-up">
              <v-flex xs12 align-self-center>
                <v-icon color="grey">mdi-account-circle-outline</v-icon>
              </v-flex>
            </v-layout>
          </template>
          <v-card tile flat :elevation="0" max-width="200px">
            <v-layout wrap pa-4 justify-center>
              <v-flex xs12 pt-3>
                <v-layout wrap justify-center>
                  <v-flex xs1><v-icon size="140%" color="black">mdi-account-outline</v-icon></v-flex>
                  <v-flex @click="$router.push('/profile')" pt-1 xs10 text-center style="
                                    font-family: dmsansregular;
                                    color: black;
                                    font-size: 14px;
                                    text-transform: none;
                                    font-weight:600;
                          cursor: pointer;  

                                  ">Accounts</v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs12 pt-4 style="cursor: pointer">
                <v-layout wrap justify-center>
                  <v-flex xs1><v-icon size="130%" color="black">mdi-shopping-outline</v-icon></v-flex>
                  <v-flex @click="$router.push('/MyOrders')" xs10 align-self-center pt-1 text-center style="
                                    font-family: dmsansregular;
                                    color: black;
                                    font-size: 14px;
                                    text-transform: none;
                                    font-weight:600;

                                  ">My orders</v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs11 pt-4>
                <v-divider></v-divider>
              </v-flex>
              <v-flex xs11 text-center pt-5 pb-4 align-self-center>
                <v-btn block @click="appLogout()" class="no-uppercase" dark color="#13736f">
                  <span style="
                                    font-family: dmsansregular;
                                    font-size: 15px;
                                    color: white;
                                    letter-spacing: 1px;
                                    cursor: pointer;
                                  ">
                    <v-icon size="130%" color="white">mdi-logout</v-icon>
                    <span>&nbsp;&nbsp;Logout</span>
                  </span>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-card>
        </v-menu>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import { mdiChevronDown } from "@mdi/js";
import axios from "axios";

export default {

  data() {
    return {
      nav: false,
      cartCount: "",
      wishlistProcartCountuctCount: "",

      profile: {},
      items: [
        { title: "Home", route: "/", type: "link" },
        {
          title: "About Us",
          route: "/AnalyticsPage",
          type: "link",
        },
        { title: "Our Team", route: "/LeadersPage", type: "link" },

        { title: "Campaign", route: "/campaign", type: "link" },
        { title: "Contact Us", route: "/ContactusPage" },
      ],
      category: [
        { title: "Home", route: "/", type: "link" },
        {
          title: "About Us",
          route: "/AnalyticsPage",
          type: "link",
          path: "#about",
        },
        { title: "Our Team", route: "/LeadersPage", type: "link" },
        { title: "Campaign", route: "/campaign", type: "link" },
        { title: "Contact Us", route: "/ContactusPage" },
      ],
    };
  },

  computed: {
    chevronDownIcon() {
      return mdiChevronDown;
    },
    appUser() {
      return null;
    },
    appLogin() {
      return false;
    },
    appType() {
      return this.$store.state.userType;
    },

    role() {
      return this.$store.state.role;
    },
    appNavItems() {
      return this.navItems;
    },
  },
  mounted() {
    this.getProfile();
  },

  methods: {
    getProfile() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/user/profile",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.profile = response.data.data;
          this.cartCount = response.data.cartCount;
          this.wishlistProductCount = response.data.wishlistProductCount;
          this.appLoading = false;
        })

        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
    appLogout() {
      this.$store.commit("logoutUser", true);
    },
  },
};
</script>
<style >
.router-link {
  text-decoration: none;
}

.navbar {
  overflow: hidden;
  background-color: #333;
  position: fixed;
  top: 0;
  width: 100%;
}

.fixItem {
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  z-index: 1;
  /* overflow: hidden;
  position: relative;
  height: 100%; */
}

.fnt1 {
  font-size: 13px;
  font-weight: bold;
}

.gradientorange {
  /* background-image:inear-gradient(255.36deg, #7BC142 48.09%, #E95D49 127.63%) ; */
  background-image: linear-gradient(255.36deg, white, white);
}

.shadow-effect {
  box-shadow: 0px 4px 11px 0px rgba(0, 0, 0, 0.08);
  /* Add background-color to the layout to see the shadow */
  background-color: white;
}

.border-line {
  border-bottom: 1px solid #ccc;
  /* Adjust the color and width as needed */
}

.zoom-card {
  transition: all 0.3s ease-in-out;
}

.zoom-card:hover {
  cursor: pointer;
  transform: scale(1.1);
  box-shadow: 0px 2px 4px #999;
}

.clr:hover {
  background-color: orange;
  border: 1px solid orange;
  color: white;
}

.myclass.v-sheet.v-card {
  border: 1px solid orange;
}
</style>