<template>
  <div>
    <v-layout wrap justify-center class="hidden-sm-and-down" mb-4
      style="height:90px;box-shadow: 0px 4px 11px 0px rgba(0, 0, 0, 0.08);">
      <v-flex xs4 pl-10 pt-4 pb-4>
        <v-layout wrap justify-start pl-10 fill-height>
          <v-flex xs1 align-self-center>
            <v-icon color="green">mdi-phone-plus-outline</v-icon>
          </v-flex>
          <v-flex xs3 text-left align-self-center>
            <v-layout wrap>
              <v-flex xs12 style="font-family: opensansregular; font-size: 14px; color: #000">Call Us</v-flex>
              <v-flex xs12 style="
                      font-family: opensansregular;
                      font-size: 14px;
                      color: #000;
                      font-weight: bold;
                    ">
                +91&nbsp;7510255897
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>

      <v-flex xs4 class="fixItem">
        <v-layout wrap justify-center fill-height>
          <v-flex style="cursor: pointer" @click="$router.push('/DashboardPage')" text-center xs12 align-self-center>
            <span style="
                    font-family: brightfont;
                    font-size: 18px;
                    color: #000;
                    font-weight: lighter;
                  ">
              Story of 1000 planters
            </span>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs4>
        <v-layout wrap fill-height>
          <v-flex xs12 align-self-center>
            <v-layout pl-14 wrap justify-center>
              <v-flex style="cursor: pointer;" @click="$router.push('/wishlist')" xs1 pt-3>
                <v-badge content="wishlistProductCount" color="red" overlap>
                  <template v-slot:badge>
                    <span class="badge-content">{{ wishlistProductCount }}</span>
                  </template>
                  <v-icon size="160%" color="black">mdi-bookmark-outline</v-icon>
                </v-badge>
              </v-flex>

              <v-flex pr-8 style="cursor: pointer;" @click="$router.push('/cart')" xs1 pt-3>
                <v-badge content="cartCount" color="red" overlap>
                  <template v-slot:badge>
                    <span class="badge-content">{{ cartCount }}</span>
                  </template>
                  <v-icon size="160%" color="black">mdi-cart-outline</v-icon>
                </v-badge>
              </v-flex>

              <v-flex xs5 pt-2>
                <!-- <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn text color="white" v-bind="attrs" v-on="on">
                      <v-icon color="black" size="195%">mdi-account-circle-outline</v-icon>&nbsp;
                      <span v-if="profile.traderId" style="
      font-family: dmsansregular;
      color: black;
      font-size: 14px;
      text-transform: none;
    ">
                        {{ profile.traderId.name }}
                      </span>

                      <span v-if="profile.farmerId" style="
      font-family: dmsansregular;
      color: black;
      font-size: 14px;
      text-transform: none;
    ">
                        {{ profile.farmerId.name }}
                      </span>

                      <span v-if="profile.customerId" style="
      font-family: dmsansregular;
      color: black;
      font-size: 14px;
      text-transform: none;
    ">
                        {{ profile.customerId.name }}
                      </span>
                      <v-icon color="black">mdi-chevron-down</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item>
                      <v-card flat width="120px">
                        <v-layout wrap>
                          <v-flex xs12 pt-3>
                            <v-layout wrap>
                              <v-flex xs1><v-icon size="140%" color="black">mdi-account-outline</v-icon></v-flex>
                              <v-flex @click="$router.push('/profile')" pt-1 xs11 text-center style="
                                    font-family: dmsansregular;
                                    color: black;
                                    font-size: 14px;
                                    text-transform: none;
                          cursor: pointer;

                                  ">Accounts</v-flex>
                            </v-layout>
                          </v-flex>
                          <v-flex xs12 pt-4>
                            <v-layout wrap>
                              <v-flex xs1><v-icon size="130%" color="black">mdi-shopping-outline</v-icon></v-flex>
                              <v-flex xs11 text-center style="
                                    font-family: dmsansregular;
                                    color: black;
                                    font-size: 14px;
                                    text-transform: none;
                                  ">My orders</v-flex>
                            </v-layout>
                          </v-flex>
                          <v-flex xs12 pt-4>
                            <v-divider></v-divider>
                          </v-flex>
                          <v-flex xs10 pt-5 pb-4>
                            <v-btn @click="appLogout()" class="no-uppercase" dark width="120px" color="#13736f">
                              <span style="
                                    font-family: dmsansregular;
                                    font-size: 15px;
                                    color: white;
                                    letter-spacing: 1px;
                                    cursor: pointer;
                                  ">
                                <v-icon size="130%" color="white">mdi-logout</v-icon>
                                <span>&nbsp;&nbsp;Logout</span>
                              </span>
                            </v-btn>
                          </v-flex>
                        </v-layout>
                      </v-card>

                    </v-list-item>
                  </v-list>
                </v-menu> -->
                <accountcard />
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>


    <v-layout class="hidden-md-and-up">
      <v-flex xs12>
        <v-layout wrap>
          <v-flex xs11 align-self-start>
            <v-layout wrap justify-center>
              <v-flex xs9 text-center pt-6> <span style="
                    font-family: brightfont;
                    font-size: 18px;
                    color: #000;
                    font-weight: lighter;
                  ">
                  Story of 1000 planters
                </span></v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs1 pt-5>
            <v-app-bar-nav-icon @click="nav = true">
              <v-icon color="White">mdi-menu</v-icon>
            </v-app-bar-nav-icon>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <v-navigation-drawer right v-model="nav" app fixed temporary>
      <v-layout wrap justify-center>
        <v-flex xs11 pt-8 style="cursor: pointer" @click="$router.push('/DashboardPage')" text-center xs12
          align-self-center>
          <span style="
                      font-family: brightfont;
                      font-size: 18px;
                      color: #000;
                      font-weight: lighter;
                    ">
            Story of 1000 planters
          </span>
        </v-flex>
        <v-flex xs11 pt-5>
          <v-layout wrap justify-start fill-height>
            <v-flex xs1 text-right align-self-center>
              <v-icon color="green">mdi-phone-plus-outline</v-icon>
            </v-flex>
            <v-flex xs11 text-center align-self-center>
              <v-layout wrap>
                <v-flex xs12 style="font-family: opensansregular; font-size: 14px; color: #000">Call Us</v-flex>
                <v-flex xs12 style="
                        font-family: opensansregular;
                        font-size: 14px;
                        color: #000;
                        font-weight: bold;
                      ">
                  +91&nbsp;7510255897
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex text-center xs3 pt-7 align-self-center>
          <span v-if="profile.traderId" style="
      font-family: dmsansregular;
      color: black;
      font-size: 14px;
      text-transform: none;
      font-weight:600;

    ">
            {{ profile.traderId.name }}
          </span>

          <span v-if="profile.farmerId" style="
      font-family: dmsansregular;
      color: black;
      font-size: 14px;
      text-transform: none;
      font-weight:600;

    ">
            {{ profile.farmerId.name }}
          </span>

          <span v-if="profile.customerId" style="
      font-family: dmsansregular;
      color: black;
      font-size: 14px;
      text-transform: none;
      font-weight:600;

    ">
            {{ profile.customerId.name }}
          </span>


        </v-flex>
        <v-flex xs3 pt-7 @click="$router.push('/wishlist')">
          <v-badge content="wishlistProductCount" color="red" overlap>
            <template v-slot:badge>
              <span class="badge-content">{{ wishlistProductCount }}</span>
            </template>
            <v-icon size="160%" color="black">mdi-bookmark-outline</v-icon>
          </v-badge>
        </v-flex>
        <v-flex xs3 pt-7 @click="$router.push('/cart')">
          <v-badge content="cartCount" color="red" overlap>
            <template v-slot:badge>
              <span class="badge-content">{{ cartCount }}</span>
            </template>
            <v-icon size="160%" color="black">mdi-cart-outline</v-icon>
          </v-badge>
        </v-flex>

        <v-flex xs11 pt-6>
          <v-layout wrap justify-center>
            <v-flex xs1><v-icon size="140%" color="black">mdi-account-outline</v-icon></v-flex>
            <v-flex @click="$router.push('/profile')" pt-1 xs10 text-center style="
                                    font-family: dmsansregular;
                                    color: black;
                                    font-size: 14px;
                                    text-transform: none;
                                    font-weight:600;
                          cursor: pointer;  

                                  ">Accounts</v-flex>
          </v-layout>

          <v-flex xs11>
            <v-layout wrap justify-center>
              <v-flex xs1><v-icon size="130%" color="black">mdi-shopping-outline</v-icon></v-flex>
              <v-flex xs10 align-self-center pt-1 text-center style="
                                    font-family: dmsansregular;
                                    color: black;
                                    font-size: 14px;
                                    text-transform: none;
                                    font-weight:600;

                                  ">My orders</v-flex>
            </v-layout>
          </v-flex>

        </v-flex>

        <v-flex xs11 text-center pt-5 pb-4 align-self-center>
          <v-btn block @click="appLogout()" class="no-uppercase" dark color="#13736f">
            <span style="
                                      font-family: dmsansregular;
                                      font-size: 15px;
                                      color: white;
                                      letter-spacing: 1px;
                                      cursor: pointer;
                                    ">
              <v-icon size="130%" color="white">mdi-logout</v-icon>
              <span>&nbsp;&nbsp;Logout</span>
            </span>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-navigation-drawer>
  </div>
</template>

<script>
import accountcard from "./AccountCard.vue";
import { mdiChevronDown } from "@mdi/js";
import axios from "axios";

export default {
  components: {
    accountcard,
  },
  data() {
    return {
      nav: false,
      cartCount: "",
      wishlistProductCount: "",
      profile: {},
      items: [
        { title: "Home", route: "/", type: "link" },
        {
          title: "About Us",
          route: "/AnalyticsPage",
          type: "link",
        },
        { title: "Our Team", route: "/LeadersPage", type: "link" },

        { title: "Campaign", route: "/campaign", type: "link" },
        { title: "Contact Us", route: "/ContactusPage" },
      ],
      category: [
        { title: "Home", route: "/", type: "link" },
        {
          title: "About Us",
          route: "/AnalyticsPage",
          type: "link",
          path: "#about",
        },
        { title: "Our Team", route: "/LeadersPage", type: "link" },
        { title: "Campaign", route: "/campaign", type: "link" },
        { title: "Contact Us", route: "/ContactusPage" },
      ],
    };
  },

  computed: {
    chevronDownIcon() {
      return mdiChevronDown;
    },
    appUser() {
      return null;
    },
    appLogin() {
      return false;
    },
    appType() {
      return this.$store.state.userType;
    },

    role() {
      return this.$store.state.role;
    },
    appNavItems() {
      return this.navItems;
    },
  },
  beforeMount() {
    // This code will be executed before the component is mounted
    this.getProfile();
  },

  methods: {
    getProfile() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/user/profile",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.profile = response.data.data;
          this.appLoading = false;
          this.cartCount = response.data.cartCount;
          this.wishlistProductCount = response.data.wishlistProductCount;
        })

        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
    appLogout() {
      this.$store.commit("logoutUser", true);
    },
  },
};
</script>
<style >
.router-link {
  text-decoration: none;
}

.navbar {
  overflow: hidden;
  background-color: #333;
  position: fixed;
  top: 0;
  width: 100%;
}

.fixItem {
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  z-index: 1;
  /* overflow: hidden;
  position: relative;
  height: 100%; */
}

.fnt1 {
  font-size: 13px;
  font-weight: bold;
}

.gradientorange {
  /* background-image:inear-gradient(255.36deg, #7BC142 48.09%, #E95D49 127.63%) ; */
  background-image: linear-gradient(255.36deg, white, white);
}

.shadow-effect {
  box-shadow: 0px 4px 11px 0px rgba(0, 0, 0, 0.08);
  /* Add background-color to the layout to see the shadow */
  background-color: white;
}

.border-line {
  border-bottom: 1px solid #ccc;
  /* Adjust the color and width as needed */
}

.zoom-card {
  transition: all 0.3s ease-in-out;
}

.zoom-card:hover {
  cursor: pointer;
  transform: scale(1.1);
  box-shadow: 0px 2px 4px #999;
}

.clr:hover {
  background-color: orange;
  border: 1px solid orange;
  color: white;
}

.myclass.v-sheet.v-card {
  border: 1px solid orange;
}

.badge-content {
  color: white;
  font-size: 12px;
  font-weight: bold;
}
</style>