<template>
  <div>
    <v-layout wrap>
      <v-flex xs12>
        <AppHeader />

      </v-flex>
<v-flex xs12>
  <v-layout wrap justify-center>
      <v-flex xs12>
        <v-layout
          fill-height
          wrap
          justify-center
          :style="$route.name == 'Dashboard'"
        >
          <v-flex xs12>
            <v-card
              :color="$route.name == 'Dashboard' ? 'transparent' : 'white'"
              :flat="$route.name == 'Dashboard' ? true : true"
            >
              <v-layout  wrap justify-center>
                <v-flex xs12>
                  <router-view></router-view>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
</v-flex>

<v-flex xs12>
  <Appfooter />
</v-flex>
    </v-layout>
  
  </div>
</template>
<script>
import axios from "axios";
import AppHeader from "@/components/Common/appHeader";
import Appfooter from "@/components/Common/Footer";

export default {
  components: {
    Appfooter,
    AppHeader,
  },
  data() {
    return {
      sideNav: true,
      list: {},
      // menus2: [
      //   {
      //     name: "Home",
      //     route: "/DashboardPage",
      //     src: require("../../assets/Images/sicon1png.png"),
      //   },
      //   {
      //     name: "Analytics",
      //     route: "/AnalyticsPage",
      //     src: require("../../assets/Images/sicon16.png"),
      //   },
      //   {
      //     name: "Auctions",
      //     route: "/AuctionsPage",
      //     src: require("../../assets/Images/sicon14.png"),
      //   },
      //   {
      //     name: "Live Auction",
      //     route: "/LiveAuctionsPage",
      //     src: require("../../assets/Images/sicon10.png"),
      //   },

      //   {
      //     name: "Category",
      //     route: "/categoryPage",
      //     src: require("../../assets/Images/sicon2.png"),
      //   },
      //   {
      //     name: "Blogs",
      //     route: "/home2",
      //     src: require("../../assets/Images/sicon4.png"),
      //   },

      //   {
      //     name: "Lot's",
      //     route: "/LotsPage",
      //     src: require("../../assets/Images/sicon8.png"),

      //   },

      //   // {
      //   //   name: "Lots",

      //   //   subMenu: [
      //   //     {
      //   //       name: "All ",
      //   //       route: "/LotsPage",
      //   //     },
      //   //     {
      //   //       name: "Upcomming ",
      //   //       route: "/Upcomminglots",
      //   //     },
      //   //     {
      //   //       name: "Live ",
      //   //       route: "/Upcomminglots",
      //   //     },
      //   //   ],
      //   // },
      //   {
      //     name: "News",
      //     route: "/NewsPage",
      //     src: require("../../assets/Images/sicon13.png"),
      //   },

      //   {
      //     name: "Users",
      //     route: "/ActiveUsersPage",
      //     src: require("../../assets/Images/sicon6.png"),
      //   },

      //   {
      //     name: "Store",
      //     route: "/StorePage",
      //     src: require("../../assets/Images/sicon3.png"),
      //   },
      // ],

      // menus3: [
      //   {
      //     name: "Home",
      //     route: "/DashboardPage",
      //     src: require("../../assets/Images/sicon1png.png"),
      //   },
      //   {
      //     name: "Auctions",
      //     route: "/AuctionsPage",
      //     src: require("../../assets/Images/sicon1png.png"),
      //   },

      //   {
      //     name: "Category",
      //     route: "/categoryPage",
      //     src: require("../../assets/Images/sicon2.png"),
      //   },
      //   {
      //     name: "Blogs",
      //     route: "/home2",
      //     src: require("../../assets/Images/sicon13.png"),
      //   },

      //   {
      //     name: "Lots",
      //     route: "/LotsPage",
      //     src: require("../../assets/Images/sicon8.png"),
      //   },
      //   {
      //     name: "News",
      //     route: "/NewsPage",
      //     src: require("../../assets/Images/sicon4.png"),
      //   },

      //   {
      //     name: "Users",
      //     route: "/ActiveUsersPage",
      //     src: require("../../assets/Images/sicon6.png"),
      //   },
      //   {
      //     name: "Store",
      //     route: "/ActiveUsersPage",
      //     src: require("../../assets/Images/sicon3.png"),
      //   },
      //   // {
      //   //   name: "Wishlist",
      //   //   route: "/StorePage",
      //   //   src: require("../../assets/Images/sicon8.png"),
      //   // },
      //   // {
      //   //   name: "Farmer",
      //   //   route: "/BlogsPage",
      //   //   src: require("../../assets/Images/sicon5.png"),
      //   // },
      //   // {
      //   //   name: "Trade",
      //   //   route: "/BlogsPage",
      //   //   src: require("../../assets/Images/sicon6.png"),
      //   // },

      //   // {
      //   //   name: "Analytics",
      //   //   route: "/BlogsPage",
      //   //   src: require("../../assets/Images/sicon4.png"),
      //   // },
      //   // {
      //   //   name: "Lots",
      //   //   route: "/BlogsPage",
      //   //   src: require("../../assets/Images/sicon3.png"),
      //   // },

      //   // {
      //   //   name: "Analytics",
      //   //   route: "/BlogsPage",
      //   //   src: require("../../assets/Images/sicon4.png"),
      //   // },
      //   // {
      //   //   name: "Manage Product Categories",
      //   //   route: "/ProductCategoryPage",
      //   // },
      //   // {
      //   //   name: "Manage Orders",
      //   //   route: "/PurchasePage",
      //   // },
      // ],
    };
  },
  computed: {
    role() {
      return this.$store.state.role;
    },
  },
  methods: {
    getList() {
      this.appLoading = true;
      axios({
        method: "post",
        url: "/authenticate/admin",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          var role = response.data.role;
          localStorage.setitem("role", role);
          this.list = response.data;
          this.appLoading = false;
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
<style>
/* .activated { */
.activated {
  color: white !important;
  /* font-size:40px  !important; */
}

.demo-bg {
  opacity: 0.6;
}
.mainbg2 {
  background-image: linear-gradient(269.6deg, #e7f1f0 -31.66%, #e7f1f0);
}

.item-text {
  display: flex;
  align-items: center;
  font-size: 18px;
  color: black;
  cursor: pointer;
  font-family: mainfont;
  margin-left: 8px; /* Adjust the margin as needed */
}

.fixedSidebar {
  /* position: fixed; */

  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 50;
}

@media only screen and (min-device-width: 360px) and (max-device-width: 640px) {
  .fixedSidebar {
    display: none !important;
  }
}
.
  @media
  only
  screen
  and
  (min-device-width: 360px)
  and
  (max-device-width: 640px) {
  .fixedSidebar {
    display: none !important;
  }
}

.content {
  /* Other styles */
  height: 40%; /* Set the height to 100% of the parent container */
}
/* #appNavbar {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 200px;
  background-color: #f2f2f2;
  overflow-y: auto;
} */

/* } */
.mainbg5 {
  background-image: linear-gradient(269.6deg, #e7f1f0 -31.66%, #e7f1f0);
  padding: 5px;
}
.mainbg6 {
  background-image: linear-gradient(269.6deg, #e7f1f0 -31.66%, #e7f1f0);
  padding: 5px;
}
.activated .content {
    background-color: #f5f5f5;
  }
  .activated .mainbg6 {
    background-color: #eeeeee;
  }
</style>